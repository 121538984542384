import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Modal, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function Services() {
    const [services, setServices] = useState([]);
    const [open, setOpen] = useState(false);
    const [newService, setNewService] = useState({ name: '', category: '' });

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem('token');

    const fetchData = async (endpoint, setter) => {
        try {
            const response = await fetch(`${apiUrl}/api/${endpoint}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (!response.ok) {
                throw new Error(`Error fetching ${endpoint}`);
            }
            const data = await response.json();
            setter(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData('services', setServices);
    }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleAddService = async () => {
        if (!newService.name || !newService.category) {
            alert('Name and category are required');
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/api/services`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(newService),
            });

            if (!response.ok) {
                throw new Error('Error saving service');
            }

            const data = await response.json();
            setServices(prevServices => [...prevServices, data]);
            setNewService({ name: '', category: '' }); // Reset input fields
            handleClose();
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeleteService = async (id) => {
        try {
            const response = await fetch(`${apiUrl}/api/services/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete service');
            }

            setServices(prevServices => prevServices.filter(service => service.id !== id));
        } catch (error) {
            console.error(error);
        }
    };


  return (
    <Box>
      <Typography variant="h6" mb={2}>Услуги</Typography>
      <Button variant="contained" color="primary" onClick={handleOpen}>Добавить услугу</Button>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Имя</TableCell>
              <TableCell>Категория</TableCell>
              <TableCell>Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((service) => (
              <TableRow key={service.id}>
                <TableCell>{service.id}</TableCell>
                <TableCell>{service.name}</TableCell>
                <TableCell>{service.category}</TableCell>
                <TableCell>
                  <IconButton color="error" onClick={() => handleDeleteService(service.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={open} onClose={handleClose}>
        <Box
          p={3}
          className="modal-box"
        >
          <Typography variant="h6" mb={2}>Добавить новую услугу</Typography>
          <TextField
            label="Имя"
            fullWidth
            value={newService.name}
            onChange={(e) => setNewService({ ...newService, name: e.target.value })}
            margin="normal"
          />
          <TextField
            label="Категория"
            fullWidth
            value={newService.category}
            onChange={(e) => setNewService({ ...newService, category: e.target.value })}
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleAddService} sx={{ mt: 2 }}>
            Добавить услугу
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

export default Services;
