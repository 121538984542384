import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {Box} from "@mui/material"; // Импортируем useAuth
import './login.css';
const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth(); // Получаем метод login из контекста
    const [rememberMe, setRememberMe] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${apiUrl}/api/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        });

        const data = await response.json();
        if (response.ok) {
            login(data.token, rememberMe); // Передаем "rememberMe"
            navigate('/'); // Перенаправляем пользователя
        } else {
            setMessage(data.message);
        }
    };


    return (

        <Box margin={3} position={"relative"} display="flex" justifyContent="center">
            <div className="box"> {/* Применяем цветную рамку */}
                <div style={{ textAlign: "center", padding: "10px" }}>
                    <h1>Вход</h1>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Имя пользователя"
                            required
                            style={{
                                marginBottom: "10px",
                                padding: "8px",
                                width: "calc(100% - 40px)", // Уменьшаем ширину для создания отступов
                                marginLeft: "20px", // Отступ слева
                                marginRight: "20px", // Отступ справа
                            }}
                        />
                        <br />
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Пароль"
                            required
                            style={{
                                marginBottom: "10px",
                                padding: "8px",
                                width: "calc(100% - 40px)", // Уменьшаем ширину для создания отступов
                                marginLeft: "20px", // Отступ слева
                                marginRight: "20px", // Отступ справа
                            }}
                        />
                        <br />
                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={rememberMe}
                                    onChange={(e) => setRememberMe(e.target.checked)}
                                    style={{
                                        marginLeft: "1rem",
                                        marginBottom: "1rem",
                                    }}
                                />
                                Запомнить меня
                            </label>
                            <br />
                            <button
                                className="styled-button"
                                style={{ marginLeft: '1rem', marginBottom: '20px' }}
                            >
                                Войти
                            </button>                        </div>
                    </form>
                    {message && <p>{message}</p>}
                </div>
            </div>
        </Box>
    );
};

export default Login;
