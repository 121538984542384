import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1565c0', // Основной цвет (заменяет синий по умолчанию)
            contrastText: '#FFFFFF', // Цвет текста на кнопках и AppBar
        },
        secondary: {
            main: '#673ab7', // Вторичный цвет
        },
        background: {
            default: '#F5F5F5', // Фон приложения
            paper: '#FFFFFF', // Фон карточек и других компонентов
        },
        text: {
            primary: '#333333', // Основной цвет текста
            secondary: '#555555', // Второстепенный текст
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif', // Глобальный шрифт
        p1: {
            fontFamily: 'Dancing Script, cursive', // Шрифт для заголовков h4
            fontWeight: 700,
        },
        button: {
            textTransform: 'none', // Убираем заглавные буквы на кнопках
            fontFamily: 'Roboto, sans-serif', // Шрифт для кнопок
        },
    },
});


export default theme;
