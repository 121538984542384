import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Modal, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function Positions() {
    const [positions, setPositions] = useState([]);
    const [open, setOpen] = useState(false);
    const [newPosition, setNewPosition] = useState({ title: '', description: '' });

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem('token');

    const fetchData = async (endpoint, setter) => {
        try {
            const response = await fetch(`${apiUrl}/api/${endpoint}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (!response.ok) {
                throw new Error(`Error fetching ${endpoint}`);
            }
            const data = await response.json();
            setter(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData('positions', setPositions);
    }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleAddPosition = async () => {
        if (!newPosition.title) {
            alert('Title is required');
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/api/positions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(newPosition),
            });

            if (!response.ok) {
                throw new Error('Error saving position');
            }

            const data = await response.json();
            setPositions(prevPositions => [...prevPositions, data]);
            setNewPosition({ title: '', description: '' }); // Reset input fields
            handleClose();
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeletePosition = async (id) => {
        try {
            const response = await fetch(`${apiUrl}/api/positions/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete position');
            }

            setPositions(prevPositions => prevPositions.filter(position => position.id !== id));
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <Box >
            <Typography variant="h6" mb={2}>Должность</Typography>
            <Button variant="contained" color="primary" onClick={handleOpen}>Добавить должность</Button>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Наименование должности</TableCell>
                            <TableCell>Описание</TableCell>
                            <TableCell>Действие</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {positions.map((position) => (
                            <TableRow key={position.id}>
                                <TableCell>{position.id}</TableCell>
                                <TableCell>{position.title}</TableCell>
                                <TableCell>{position.description}</TableCell>
                                <TableCell>
                                    <IconButton color="error" onClick={() => handleDeletePosition(position.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Modal for adding new positions */}
            <Modal open={open} onClose={handleClose}>
                <Box
                    p={3}
                    className="modal-box"
                >
                    <Typography variant="h6" mb={2}>Добавить новую должность</Typography>
                    <TextField
                        label="Наименование должности"
                        fullWidth
                        value={newPosition.title}
                        onChange={(e) => setNewPosition({ ...newPosition, title: e.target.value })}
                        margin="normal"
                    />
                    <TextField
                        label="Описание"
                        fullWidth
                        multiline
                        rows={4}
                        value={newPosition.description}
                        onChange={(e) => setNewPosition({ ...newPosition, description: e.target.value })}
                        margin="normal"
                    />
                    <Button variant="contained" color="primary" onClick={handleAddPosition} sx={{ mt: 2 }}>
                        Добавить должность
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
}

export default Positions;
