import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem } from '@mui/material';

const daysOfWeek = [
  { day: 'Monday', type: 'weekday' },
  { day: 'Tuesday', type: 'weekday' },
  { day: 'Wednesday', type: 'weekday' },
  { day: 'Thursday', type: 'weekday' },
  { day: 'Friday', type: 'weekday' },
  { day: 'Saturday', type: 'weekend' },
  { day: 'Sunday', type: 'weekend' },
];

function WorkSchedule() {
    const [workingHours, setWorkingHours] = useState([]);

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem('token');

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/work-schedule`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Error fetching work schedule');
            }

            const data = await response.json();
            if (data.length > 0) {
                setWorkingHours(data);  // Устанавливаем полученные данные с сервера
            } else {
                // Если данных нет, устанавливаем стандартные часы работы
                setWorkingHours(
                    daysOfWeek.map(day => ({
                        day: day.day,
                        type: day.type,
                        start: '09:00:00',
                        end: day.type === 'weekend' ? '15:00:00' : '18:00:00',
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching work schedule:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Handle changes in input fields
    const handleChange = (index, field, value) => {
        const newWorkingHours = [...workingHours];
        newWorkingHours[index][field] = value;
        setWorkingHours(newWorkingHours);
    };

    // Save work schedule to backend
    const handleSave = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/work-schedule`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(workingHours),
            });

            if (!response.ok) {
                throw new Error('Error saving work schedule');
            }

            const data = await response.json();
            console.log('Work schedule saved:', data);
            alert('Work schedule saved successfully!');
        } catch (error) {
            console.error('Error saving work schedule:', error);
        }
    };


  return (
    <Box>
      <Typography variant="h6" mb={2}>График работы</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>День</TableCell>
              <TableCell>Тип</TableCell>
              <TableCell>Начало рабочего дня</TableCell>
              <TableCell>Конец рабочего дня</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workingHours.map((entry, index) => (
              <TableRow key={index}>
                <TableCell>{entry.day}</TableCell>
                <TableCell>
                  <TextField
                    select
                    value={entry.type}
                    onChange={(e) => handleChange(index, 'type', e.target.value)}
                    fullWidth
                  >
                    <MenuItem value="weekday">Рабочий день</MenuItem>
                    <MenuItem value="weekend">Выходной</MenuItem>
                  </TextField>
                </TableCell>
                <TableCell>
                  <TextField
                    type="time"
                    value={entry.start}
                    onChange={(e) => handleChange(index, 'start', e.target.value)}
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="time"
                    value={entry.end}
                    onChange={(e) => handleChange(index, 'end', e.target.value)}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        sx={{ mt: 3 }}
      >
        Сохранить график
      </Button>
    </Box>
  );
}

export default WorkSchedule;
