// src/context/AuthContext.js
import React, { createContext, useContext, useState , useEffect} from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('token'));

    const login = (newToken, rememberMe) => {
        if (rememberMe) {
            localStorage.setItem('token', newToken); // Токен сохраняется в localStorage
        } else {
            sessionStorage.setItem('token', newToken); // Токен сохраняется в sessionStorage
        }
        setToken(newToken);
    };

    const logout = () => {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        setToken(null);
    };

// При загрузке проверяем наличие токена
    useEffect(() => {
        const storedToken = localStorage.getItem('token') || sessionStorage.getItem('token');
        if (storedToken) {
            setToken(storedToken);
        }
    }, []);


    return (
        <AuthContext.Provider value={{ token, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);